var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dn-tag-list" }, [
    _vm.renderList
      ? _c(
          "ul",
          { staticClass: "dn-tag-list__list" },
          _vm._l(_vm.list, function (tag) {
            return _c(
              "li",
              { key: tag._id, staticClass: "dn-tag-list__list-item" },
              [_c("tag", { attrs: { text: tag.name } })],
              1
            )
          }),
          0
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }